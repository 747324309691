
import { defineComponent } from 'vue';
import { JwtUser } from '@/interfaces/identity/user';
import { redirectToDiscord } from '@/helpers';
import { ROLE_ADMINISTRATOR } from '@/constants/roles';

export default defineComponent({
    name: 'AppBar',
    computed: {
        user(): JwtUser | null {
            return this.$store.getters['authentication/user'];
        },
        isAdmin(): boolean {
            return this.$store.getters['authentication/hasOneRoles']([ROLE_ADMINISTRATOR]);
        },
        views(): number {
            return this.$store.getters['views/views'];
        },
        userAvatarStyle(): Record<string, string> {
            if (!this.user) {
                return {};
            }
            return {
                backgroundImage: `url(${this.user.avatarUri})`,
            };
        },
    },
    methods: {
        redirectToDiscord,
        openMainNav(): void {
            this.$store.commit('mainNav/setOpen', true);
        },
        signOut(): void {
            this.$store.commit('authentication/deleteUser');
        },
    },
});
